(function(LIVEREZ, window, $, undefined) {
  const StripCalendar = function(starDate, endDate, datesArr) {
    var self = this;

    this.startDate = new Date(starDate);
    this.endDate = new Date(endDate);
    this.numberOfDays = getDateDiff(self.startDate, self.endDate);
    this.data = datesArr;

    this.startingMonth = this.startDate.getMonth();
    this.stoppingMonth = this.endDate.getMonth();

    this.displayDates = getDates(self.startDate, self.endDate);
  };

  var _isLeapYear = function(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  };

  var _getDaysInMonth = function(year, month) {
    return [31, _isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
  };

  var _dates = {
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    daysSuperShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    monthsShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  };

  StripCalendar.prototype.generateHtml = function(callback) {
    var container = $('<div />').addClass('table-responsive');
    var table = $('<table />').addClass('lr-strip-calendar');

    var _daysInEachMonth = getDaysInEachMonth(this.displayDates);

    var headingRow = $('<tr />').addClass('lr-strip-calendar--heading-row');
    var weekdayRow = $('<tr />').addClass('lr-strip-calendar--weekday-row');
    var dateRow = $('<tr />').addClass('lr-strip-calendar--date-row');

    // add months to heading row
    for (var key in _daysInEachMonth) {
      var monthIndex = key.split('_')[1];
      var month = $('<td />')
        .text(_dates.monthsShort[monthIndex])
        .attr('colspan', _daysInEachMonth[key]);
      headingRow.append(month);
    }

    // add days to the date row
    this.displayDates.forEach(function(item) {
      var weekday = $('<td />').text(_dates.daysSuperShort[item.getDay()]);
      weekday.addClass('lr-strip-calendar--weekday');

      weekdayRow.append(weekday);

      var day = $('<td />').text(item.getDate());
      day.addClass('lr-strip-calendar--day ' + formatDate(item));

      dateRow.append(day);
    });

    // paint the days with the correct classes
    this.data.forEach(function(calendarDay) {
      var current = dateRow.children('.' + formatDate(calendarDay.date));

      if (calendarDay.checkIn) {
        current.addClass('checkin');
      }
      if (calendarDay.checkOut) {
        current.addClass('checkout');
      }
      if (calendarDay.occupied) {
        current.addClass('occupied');
      }
    });

    table.append(headingRow);
    table.append(weekdayRow);
    table.append(dateRow);

    container.append(table);

    callback(container.html());
  };

  return (LIVEREZ.StripCalendar = StripCalendar);

  function formatDate(date) {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  }

  function getDateDiff(date1, date2) {
    return Math.abs((date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24));
  }

  function getDates(d1, d2) {
    var dates = [];
    var currentDate = new Date(d1);
    var endDate = new Date(d2);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  function getDaysInEachMonth(datesArr) {
    var obj = {};

    datesArr.forEach(function(item) {
      var _month = item.getMonth();
      var _year = item.getFullYear();

      var key = _year + '_' + _month;

      if (!obj.hasOwnProperty(key)) {
        obj[key] = 1;
      } else {
        obj[key] = obj[key] + 1;
      }
    });

    return obj;
  }
})((window.LIVEREZ = window.LIVEREZ || {}), window, lrjQ);
