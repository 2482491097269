/*
 * @author Joshua Hurst
 * @date 5/27/2014
 *
 * @description A set of functions to be used with LiveRez Dynsites
 */

(function(LIVEREZ, window, $, undefined) {
  var DynsiteFunctions = {};

  DynsiteFunctions.PopUp = function(URL, Window, w, h, x, y) {
    var popupwin = window.open(
      URL,
      Window,
      'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=' +
        w +
        ',height=' +
        h +
        ',left=' +
        x +
        ',top=' +
        y
    );
  };

  DynsiteFunctions.socialSharePage = function(socialType, image, headline) {
    var url;
    var page = encodeURIComponent(window.location.href).toString();
    image = encodeURIComponent(image);
    headline = encodeURIComponent(headline);

    switch (socialType) {
      case 'facebook':
        url = 'https://www.facebook.com/sharer.php?u=' + page;
        break;

      case 'twitter':
        url =
          'https://twitter.com/intent/tweet?source=tweetbutton&text=' + headline + '&url=' + page;
        break;

      case 'pinterest':
        url =
          'https://www.pinterest.com/pin/create/button/?url=' +
          page +
          '&media=' +
          image +
          '&description=' +
          headline;
        break;

      case 'googlePlus':
        url = 'https://plus.google.com/share?url=' + page;
        break;
    }

    this.PopUp(url, window, 400, 350);
  };

  DynsiteFunctions.isEmailAddr = function(email) {
    var result = false;
    var theStr = new String(email);
    var index = theStr.indexOf('@');
    if (index > 0) {
      var pindex = theStr.indexOf('.', index);
      if (pindex > index + 1 && theStr.length > pindex + 1) result = true;
    }
    return result;
  };

  DynsiteFunctions.validRequired = function(formField, fieldLabel) {
    var result = true;
    if (formField.value == '') {
      alert('Please enter a value for the "' + fieldLabel + '" field.');
      formField.focus();
      result = false;
    }
    return result;
  };

  DynsiteFunctions.allDigits = function(str) {
    return this.inValidCharSet(str, '0123456789');
  };

  DynsiteFunctions.inValidCharSet = function(str, charset) {
    var result = true;
    // Doesn't use regular expressions to avoid early Mac browser bugs
    for (var i = 0; i < str.length; i++) {
      if (charset.indexOf(str.substr(i, 1)) < 0) {
        result = false;
        break;
      }
    }
    return result;
  };

  DynsiteFunctions.validEmail = function(formField, fieldLabel, required) {
    var result = true;
    if (required && !this.validRequired(formField, fieldLabel)) result = false;
    if (result && (formField.value.length < 3 || !this.isEmailAddr(formField.value))) {
      alert('Please enter a complete email address in the form: yourname@yourdomain.com');
      formField.focus();
      result = false;
    }
    return result;
  };

  DynsiteFunctions.validNum = function(formField, fieldLabel, required) {
    var result = true;
    if (required && !this.validRequired(formField, fieldLabel)) result = false;
    if (result) {
      if (!this.allDigits(formField.value)) {
        alert('Please enter a number for the "' + fieldLabel + '" field.');
        formField.focus();
        result = false;
      }
    }
    return result;
  };

  DynsiteFunctions.validInt = function(formField, fieldLabel, required) {
    var result = true;
    if (required && !this.validRequired(formField, fieldLabel)) result = false;
    if (result) {
      var num = parseInt(formField.value, 10);
      if (isNaN(num)) {
        alert('Please enter a number for the "' + fieldLabel + '" field.');
        formField.focus();
        result = false;
      }
    }
    return result;
  };

  DynsiteFunctions.validDate = function(formField, fieldLabel, required) {
    var result = true;

    if (required && !this.validRequired(formField, fieldLabel)) result = false;
    if (result) {
      var elems = formField.value.split('/');
      result = elems.length == 3; // should be three components
      if (result) {
        var month = parseInt(elems[0], 10);
        var day = parseInt(elems[1], 10);
        var year = parseInt(elems[2], 10);
        result =
          allDigits(elems[0]) &&
          month > 0 &&
          month < 13 &&
          allDigits(elems[1]) &&
          day > 0 &&
          day < 32 &&
          allDigits(elems[2]) &&
          (elems[2].length == 2 || elems[2].length == 4);
      }
      if (!result) {
        alert('Please enter a date in the format MM/DD/YYYY for the "' + fieldLabel + '" field.');
        formField.focus();
      }
    }
    return result;
  };

  DynsiteFunctions.getServerName = function() {
    if (!window.location.origin) {
      return (
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '')
      );
    } else {
      return window.location.origin;
    }
  };

  DynsiteFunctions.goPropertyByElem = function(selectObj) {
    var val = selectObj.options[selectObj.selectedIndex].value;
    if (val != '') {
      window.parent.location.href = val;
    }
  };

  DynsiteFunctions.goPropertyByID = function(PageDataID) {
    if (PageDataID) {
      window.parent.location.href = '/vacation-rental-home.asp?PageDataID=' + PageDataID;
    }
  };

  DynsiteFunctions.sendtofriend = function(PageDataID) {
    window.parent.location.href = '/vacation-rental-home-stf.asp?PageDataID=' + PageDataID;
  };

  DynsiteFunctions.propertycontact = function(PageDataID, AD, DD) {
    window.parent.location.href =
      '/vacation-rental-home-contact.asp?PageDataID=' + PageDataID + '&ad=' + AD + '&dd=' + DD;
  };

  DynsiteFunctions.viewproperty = function(strPageDataID, strUnitID) {
    var formObj = document.viewproperty;
    formObj.action = '/vacation-rental-home.asp?PageDataID=' + strPageDataID;
    formObj.PageDataID.value = strPageDataID;
    formObj.WebReferencePageDataID.value = strPageDataID;
    formObj.InventoryUnitDataID.value = strUnitID;
    formObj.submit();
  };

  DynsiteFunctions.debounce = function(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this,
        args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  DynsiteFunctions.DateShort = function(DateStr, delimeter) {
    var tmpDate = new Date(DateStr);
    (delimeter = delimeter || '/'),
      (tmpDate =
        tmpDate.getMonth() + 1 + delimeter + tmpDate.getDate() + delimeter + tmpDate.getFullYear());
    return tmpDate;
  };

  DynsiteFunctions.DateNatural = (d, includeYear = true) => {
    if (typeof d === 'string') {
      if (d.indexOf('-') > 0) {
        d = new Date(d);
        d.setHours(d.getHours() + new Date().getTimezoneOffset() / 60);
      } else {
        d = new Date(d);
      }
    }

    var MONTH_NAMES = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var DAY_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    function nth(d) {
      if (d > 3 && d < 21) return 'th'; // thanks kennebec
      switch (d % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    }

    let final =
      DAY_NAMES[d.getDay()] +
      ' ' +
      MONTH_NAMES[d.getMonth()] +
      ' ' +
      d.getDate() +
      nth(d.getDate());

    if (includeYear) {
      final += ', ' + d.getFullYear();
    }

    return final;
  };

  /***** NAVIS 800 Number and Keyword Tracking *****/
  DynsiteFunctions.handleNavisCookies = function(NavisKeyword, NavisPhone) {
    if (NavisKeyword !== '' && NavisPhone !== '') {
      LIVEREZ.Storage.set(DYN_SITE_ID + 'navis800Num', NavisPhone);
      LIVEREZ.Storage.set(DYN_SITE_ID + 'NavisHiddenKeywordVal', NavisKeyword);
      this.fix800NumberForNavis();
    }

    if (LIVEREZ.Storage.get(DYN_SITE_ID + 'navis800Num')) {
      this.fix800NumberForNavis();
    }
  };

  DynsiteFunctions.fix800NumberForNavis = function() {
    // get the cookies
    var NavisHiddenPhoneVal = LIVEREZ.Storage.get(DYN_SITE_ID + 'navis800Num');
    var NavisHiddenKeywordVal = LIVEREZ.Storage.get(DYN_SITE_ID + 'NavisHiddenKeywordVal');

    // insert phone number into holder span
    $('.phone-link').attr('href', 'tel: ' + NavisHiddenPhoneVal);
    $('.800PhoneHolder').text(NavisHiddenPhoneVal);

    var NavisHiddenKeyword = $('#NavisHiddenKeyword');
    if (NavisHiddenKeywordVal && NavisHiddenKeyword) {
      NavisHiddenKeyword.val(NavisHiddenKeywordVal);
    }

    var NavisHiddenPhone = $('#NavisCode');
    if (NavisHiddenPhone && NavisHiddenPhoneVal) {
      NavisHiddenPhone.val(NavisHiddenPhoneVal);
    }
  };
  /***** END NAVIS 800 Number and Keyword Tracking *****/

  /***** CRM PLUS LEAD Tracking ************************/
  DynsiteFunctions.handleCRMPlusCookies = function(LeadID) {
    if (LIVEREZ.Storage.get(DYN_SITE_ID + 'LeadID')) {
      this.insertLeadCookie();
    } else {
      if (LeadID !== '') {
        LIVEREZ.Storage.set(DYN_SITE_ID + 'LeadID', LeadID);
        this.insertLeadCookie();
      }
    }
  };

  DynsiteFunctions.insertLeadCookie = function() {
    var leadCookie = LIVEREZ.Storage.get(DYN_SITE_ID + 'LeadID');
    var leadIDInput = $('.hiddenLeadID');
    if (leadCookie && leadIDInput) {
      leadIDInput.val(leadCookie);
    }
  };
  /***** END CRM PLUS LEAD Tracking *******************/

  DynsiteFunctions.trimDate = function(strDate) {
    if (strDate && typeof strDate === 'string') {
      strDate = strDate.replace(/^0+/, '');
      strDate = strDate.replace(/\/0/, '/');
    }

    return strDate;
  };

  DynsiteFunctions.fixEuroDatesForAPI = function(strDate, dateFormat) {
    if (dateFormat === 1) {
      var parts = strDate.split('/');
      return parts[1] + '/' + parts[0] + '/' + parts[2];
    } else {
      return strDate;
    }
  };

  DynsiteFunctions.dateStringToISO8601 = function(dateString) {
    var myDate = dateString.split('/');
    var returnDate = [myDate[2], myDate[0], myDate[1]].join('-');
    return returnDate;
  };

  DynsiteFunctions.dateObjectToString = function(dateObj) {
    return dateObj.getMonth() + 1 + '/' + dateObj.getDate() + '/' + dateObj.getFullYear();
  };

  DynsiteFunctions.dateStringToObject = function(dateStr) {
    var year, month, day;
    if (dateStr.indexOf('-') > -1) {
      var parts = dateStr.split('-');
      if (parts[0].length === 4) {
        year = parts[0];
        month = parts[1];
        day = parts[2];
      } else {
        year = parts[2];
        month = parts[0];
        day = parts[1];
      }
    } else {
      var parts = dateStr.split('/');
      year = parts[2];
      month = parts[0];
      day = parts[1];
    }

    return new Date(year, month - 1, day);
  };

  DynsiteFunctions.truncate = function(string, length, elipsisBool) {
    var _str = string.substring(0, length);
    if (string.length > length && elipsisBool) {
      _str += '...';
    }
    return _str;
  };

  DynsiteFunctions.daydiff = function(first, second) {
    return (second - first) / (1000 * 60 * 60 * 24);
  };

  DynsiteFunctions.checkValidStartEndDates = function(date1, date2) {
    var now = new Date().setHours(0, 0, 0, 0);
    date1 = new Date(date1).setHours(0, 0, 0, 0);
    date2 = new Date(date2).setHours(0, 0, 0, 0);

    var numSearchedDay = this.daydiff(date1, date2);
    var maxDays = maxWebRentalDays || 365;

    if (date1 === date2) {
      return 'Arrival and Departure dates cannot be the same.';
    }

    if (now > date1) {
      return 'Arrival date must be in the future.';
    }

    if (now > date2) {
      return 'Departure date must be in the future.';
    }

    if (date2 <= date1) {
      return 'Departure date must come after arrival date.';
    }

    if (numSearchedDay > maxDays) {
      return (
        'Travel Dates cannot span more than ' +
        maxDays +
        ' days. Please contact us for additional booking options.'
      );
    }
  };

  DynsiteFunctions.isMobileDevice = function() {
    var check = false;
    (function(a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  DynsiteFunctions.checkForEmailElementToHide = function() {
    var div = document.getElementById('email-div');
    if (div) {
      div.style.display = 'none';
    }
  };

  DynsiteFunctions.fixTextForDisplay = function(strText) {
    if (strText) {
      strText = strText.replace(/`/g, "'");
    }

    return strText;
  };

  LIVEREZ.DynsiteFunctions = DynsiteFunctions;
})((window.LIVEREZ = window.LIVEREZ || {}), window, lrjQ);

$(window).on('load', function() {
  // check for inputs to hide on load
  LIVEREZ.DynsiteFunctions.checkForEmailElementToHide();
});
