/*
 * @author Joshua Hurst
 * @date 6/19/2015
 *
 * @description A Vanilla JS Wrapper for Local Storage
 */

(function(LIVEREZ, window, $, undefined) {
  'use strict';

  var LocalStorage = {};

  // test if local storage is supported
  LocalStorage.localStoreSupport = function() {
    try {
      localStorage.setItem('localstoragesupporttest', 1);
      localStorage.removeItem('localstoragesupporttest');
      return 'localStorage' in window && window['localStorage'];
    } catch (e) {
      return false;
    }
  };

  // save the data
  LocalStorage.set = function(key, value, days) {
    value = JSON.stringify(value);

    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = '; expires=' + date.toGMTString();
    } else {
      var expires = '';
    }

    if (this.localStoreSupport()) {
      localStorage.setItem(key, value);
    } else {
      document.cookie = key + '=' + value + expires + '; path=/';
    }
  };

  // retrieve the stored data by key if it exists
  LocalStorage.get = function(key) {
    if (this.localStoreSupport()) {
      if (this.exists(key)) {
        return JSON.parse(localStorage.getItem(key));
      }
    } else {
      var nameEQ = key + '=';
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return JSON.parse(c.substring(nameEQ.length, c.length));
      }
      return null;
    }
  };

  // remove stored data by key if it exists
  LocalStorage.remove = function(key) {
    if (this.localStoreSupport()) {
      if (this.exists(key)) {
        localStorage.removeItem(key);
      }
    } else {
      this.set(key, '', -1);
    }
  };

  LocalStorage.exists = function(key) {
    return localStorage.getItem(key) !== null;
  };

  LIVEREZ.Storage = LocalStorage;
})((window.LIVEREZ = window.LIVEREZ || {}), window, lrjQ);
